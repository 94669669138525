import React from 'react'

export const Caption = ({ icon, children }: { icon: React.ReactNode; children: React.ReactNode }) => {
  return (
    <Caption.Container>
      <Caption.Icon>{icon}</Caption.Icon>
      <Caption.Label>{children}</Caption.Label>
    </Caption.Container>
  )
}
Caption.Container = ({ children }: { children?: React.ReactNode }) => (
  <header data-cy='captionContainerTestAttribute' className={`ml-caption-container`}>{children}</header>
)
Caption.Icon = ({ children }: { children?: React.ReactNode }) => <div className={`ml-caption-icon`}>{children}</div>
Caption.Label = ({ children }: { children?: React.ReactNode }) => <div className={`ml-caption-label`}>{children}</div>
